import styled from 'styled-components';
import { ChevronDownIcon } from 'xyz-icon-set-react';
import { Typography } from '../Typography';
import { BreadcrumbItem } from './BreadcrumbItem';

export interface BreadcrumbItemType {
  label: string;
  href?: string;
}

export interface BreadcrumbProps {
  items?: BreadcrumbItemType[];
}

const BreadcrumbComponent: React.FC<BreadcrumbProps> & {
  Item: typeof BreadcrumbItem;
} = ({ items }) => {
  return (
    <Root>
      <BreadcrumbItem>
        <Link href="/">
          <Typography.Caption size="L" fontWeight={600}>
            Ondřej Bárta
          </Typography.Caption>
        </Link>
      </BreadcrumbItem>

      {items &&
        items.map((item, index) => (
          <BreadcrumbItem key={index}>
            <Chevron>
              <ChevronDownIcon />
            </Chevron>

            <Link href={item.href}>
              <Typography.Caption size="L" fontWeight={600}>
                {item.label}
              </Typography.Caption>
            </Link>
          </BreadcrumbItem>
        ))}
    </Root>
  );
};

const Root = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
  flex-wrap: wrap;
  writing-mode: vertical-lr;
  height: 100%;
  gap: 12px;
  padding-top: 24px;

  @media ${({ theme }) => theme.media.phone} {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const Chevron = styled.div`
  width: 12px;
  height: 12px;
  display: flex;
  margin-inline-end: 12px;
  margin-block-start: -2px;

  @media ${({ theme }) => theme.media.phone} {
    align-self: flex-start;
  }

  svg {
    width: 100%;
    height: 100%;
    margin: 0;
    zoom: 0.5;

    @media ${({ theme }) => theme.media.phone} {
      transform: rotateZ(135deg);
    }
  }
`;

const Link = styled.a`
  margin: -4px -8px;
  padding: 4px 8px;
  text-decoration: none;
  color: ${({ theme }) => theme.foreground};
`;

BreadcrumbComponent.Item = BreadcrumbItem;

export const Breadcrumb = BreadcrumbComponent;
