import { useState, useEffect } from 'react';

export const useLocationHash = (hash: string) => {
  const [isFocused, setIsFocused] = useState<boolean>(false);

  useEffect(() => {
    // If location object is undefined, the rendering is not running
    // in the context of a browser
    if (!location) {
      return;
    }

    const onHashChange = () => {
      if (location.hash === `#${hash}`) {
        setIsFocused(true);
      } else {
        setIsFocused(false);
      }
    };
    addEventListener('hashchange', onHashChange);
    return () => {
      removeEventListener('hashchange', onHashChange);
    };
  }, []);

  return isFocused;
};
