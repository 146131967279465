import { rgba } from 'polished';
import React from 'react';
import styled from 'styled-components';
import { Reveal, RevealProps } from '../Reveal';

export interface TagProps {
  children?: React.ReactNode;
  className?: string;
  /**
   * Click event handler
   */
  onClick?: React.EventHandler<React.MouseEvent<HTMLButtonElement>>;
  /**
   * Visual variant of the tag
   */
  variant?: 'solid';
  /**
   * Tag size
   */
  size?: 'M';
  /**
   * Icon to be displayed before tag label
   */
  leadingIcon?: React.ReactNode;
  /**
   * Icon to be displayed after tag label
   */
  trailingIcon?: React.ReactNode;
}

export const Tag: React.FC<React.PropsWithChildren<TagProps>> = props => {
  const {
    children,
    variant = 'solid',
    size = 'M',
    leadingIcon,
    trailingIcon,
  } = props;

  return (
    <Root {...props} variant={variant}>
      {leadingIcon && <LeadingIcon size={size}>{leadingIcon}</LeadingIcon>}

      <Label size={size} variant={variant}>
        {children}
      </Label>

      {trailingIcon && <TrailingIcon size={size}>{trailingIcon}</TrailingIcon>}
    </Root>
  );
};

const RootComponent: React.FC<React.PropsWithChildren<TagProps>> = ({
  variant,
  leadingIcon,
  trailingIcon,
  ...props
}) => <button {...props} />;

const Root = styled(RootComponent)`
  position: relative;
  display: inline-block;
  box-sizing: border-box;
  border: none;
  color: ${({ theme }) => theme.foreground};
  font-weight: ${({ variant }) => (variant === 'solid' ? 500 : 400)};
  padding: 0;
  margin: 0;
  cursor: pointer;
  height: 24px;
  transition: all ${({ theme }) => theme.transition}, opacity 50ms;
  background: ${({ theme }) => rgba(theme.background, 0.5)};
  border: 1px solid ${({ theme }) => rgba(theme.foreground, 0.1)};
  backdrop-filter: blur(4px);
  border-radius: 12px;

  &:focus {
    outline: none;
  }

  &:active {
    opacity: 0.5;
  }
`;

const RevealBorderComponent: React.FC<React.PropsWithChildren<{
  tagSize: TagProps['size'];
} & Pick<TagProps, 'variant'> &
  RevealProps>> = ({ tagSize, variant, ...props }) => <Reveal {...props} />;

const RevealBorder = styled(RevealBorderComponent)`
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  box-sizing: border-box;
  border-radius: 24px;
  border: 1px solid
    ${({ theme, variant }) =>
      variant === 'solid' ? 'transparent' : theme.foreground};
`;

const IconComponent: React.FC<React.PropsWithChildren<Pick<TagProps, 'size'>>> = ({
  size,
  ...props
}) => <div {...props} />;

const Icon = styled(IconComponent)`
  margin: 4px 12px;
  line-height: 0;
`;

const LeadingIcon = styled(Icon)`
  margin-right: -8px;
`;

const TrailingIcon = styled(Icon)`
  margin-left: -8px;
`;

const LabelText = styled.span<TagProps>(({ theme, size }) => {
  const tagLabelStyle = theme.typography.tag.label[size as 'M'];

  return {
    ...theme.typography.tag.label.default,
    ...tagLabelStyle,
  };
});

const Label = styled(LabelText)`
  display: inline-block;
  color: currentColor;
  margin: 4px 12px;
  flex-grow: 1;
  text-decoration: none;
  font-weight: ${({ variant }) => (variant === 'solid' ? 600 : 600)};
`;
