import React from 'react';
import styled from 'styled-components';

export interface PageProps {
  temp?: any;
}

export const Page: React.FC<React.PropsWithChildren<PageProps>> = props => {
  return <Root>{props.children}</Root>;
};

const Root = styled.div`
  overflow: hidden;
  height: 100%;
  transition: ${props => props.theme.transition};
`;
