import { Base } from './Base';
import { Caption } from './Caption';
import { Headline, HeadlineProps } from './Headline';
import { Label } from './Label';
import { Link } from './Link';
import { Mark } from './Mark';
import { Paragraph } from './Paragraph';

export const Typography = {
  Base,
  Caption,
  Headline,
  Label,
  Link,
  Mark,
  Paragraph,
};

export type {
  HeadlineProps
}
