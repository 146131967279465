import React from 'react';
import styled from 'styled-components';
import { Theme } from '../../themes';
import { DisplayProps, displayHelper } from '../../utils/displayHelper';
import { MarginProps, marginHelper } from '../../utils/marginHelper';
import { MaxWidthProps, maxWidthHelper } from '../../utils/maxWidthHelper';
import { PaddingProps, paddingHelper } from '../../utils/paddingHelper';
import {
  ResponsiveProperty,
  getResponsiveProperty,
} from '../../utils/responsive';

export interface BoxProps
  extends DisplayProps,
    MarginProps,
    PaddingProps,
    MaxWidthProps {
  className?: string;
  id?: string;
  aspectRatio?: ResponsiveProperty<React.CSSProperties['aspectRatio']>;
  height?: ResponsiveProperty<React.CSSProperties['height']>;
  minHeight?: ResponsiveProperty<React.CSSProperties['minHeight']>;
  children?: React.ReactNode;
}

export const Box: React.FC<React.PropsWithChildren<BoxProps>> = (props) => {
  return <Root {...props} />;
};

const RootComponent: React.FC<React.PropsWithChildren<BoxProps>> = ({
  className,
  children,
  id,
}) => (
  <div className={className} id={id}>
    {children}
  </div>
);
const Root = styled(RootComponent)`
  box-sizing: border-box;
  ${({ theme, aspectRatio }) =>
    getResponsiveProperty('aspectRatio', aspectRatio, theme as Theme)}
  ${displayHelper}
  ${marginHelper}
  ${paddingHelper}
  ${maxWidthHelper}

  ${({ theme, height }) =>
    getResponsiveProperty('height', height, theme as Theme)}
  ${({ theme, minHeight }) =>
    getResponsiveProperty('minHeight', minHeight, theme as Theme)}
`;
