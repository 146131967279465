import React from 'react';
import { rgba } from 'polished';
import styled from 'styled-components';
import { Reveal, RevealProps } from '../Reveal';
import { marginHelper, MarginProps } from '../../utils/marginHelper';
import { displayHelper, DisplayProps } from '../../utils/displayHelper';

export interface DividerVerticalProps
  extends DisplayProps,
    MarginProps,
    RevealProps {
  className?: string;
  disabledReveal?: boolean;
}

export const DividerVertical: React.FC<
  React.PropsWithChildren<DividerVerticalProps>
> = (props) => {
  const { className, disabledReveal } = props;
  const revealProps: RevealProps = {};

  if (disabledReveal) {
    revealProps.activeOpacity = 1;
    revealProps.inactiveOpacity = 1;
  }

  return <Root className={className} {...revealProps} {...props} />;
};

const Root = styled(Reveal)<DividerVerticalProps>`
  height: 100%;
  width: ${({ theme }) => theme.divider.width}px;
  background: ${({ theme }) => rgba(theme.foreground, theme.divider.opacity)};
  transition: ${({ theme }) => theme.transition};
  ${displayHelper}
  ${marginHelper}
`;
