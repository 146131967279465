import { CSSProperties } from 'react';
import { css } from 'styled-components';
import { Theme } from '../themes';
import { ResponsiveProperty, getResponsiveProperty } from './responsive';

export type DisplayType = ResponsiveProperty<CSSProperties['display']>;

export interface DisplayProps {
  display?: DisplayType;
}

export const displayHelper = css<DisplayProps>(
  ({ theme, display, ...props }) => {
    return css`
      ${getResponsiveProperty('display', display, theme as Theme)}
    `;
  }
);
