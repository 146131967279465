import { useEffect, useState } from 'react';
import * as THREE from 'three';

export const useWebGLRenderer = (
  setUpRenderer?: (renderer: THREE.WebGLRenderer) => void
) => {
  const [renderer, setRenderer] = useState<THREE.WebGLRenderer | undefined>();

  useEffect(() => {
    initRenderer();
    updateRenderer();

    addEventListener('resize', updateRenderer);
  }, []);

  const initRenderer = () => {
    const WebGLRenderer = new THREE.WebGLRenderer({
      antialias: true,
      alpha: true,
    });

    setRenderer(WebGLRenderer);

    if (setUpRenderer) setUpRenderer(WebGLRenderer);

    WebGLRenderer.shadowMap.enabled = true;
    WebGLRenderer.shadowMap.type = THREE.PCFSoftShadowMap;
  };

  const updateRenderer = () => {
    if (!renderer) return;

    renderer.setPixelRatio(Math.min(devicePixelRatio, 2));
  };

  return { renderer, updateRenderer };
};
