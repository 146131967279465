import React from 'react';
import { rgba } from 'polished';
import styled, { keyframes } from 'styled-components';
import { Reveal, RevealProps } from '../Reveal';
import { displayHelper, DisplayProps } from '../../utils/displayHelper';

export interface ButtonProps extends DisplayProps {
  children?: React.ReactNode;
  className?: string;
  /**
   * Click event handler
   */
  onClick?: React.EventHandler<React.MouseEvent<HTMLButtonElement>>;
  /**
   * Visual variant of the button
   */
  variant?: 'solid' | 'hollow';
  /**
   * Button size
   */
  size?: 'S' | 'M';
  /**
   * Icon to be displayed before button label
   */
  leadingIcon?: React.ReactNode;
  /**
   * Icon to be displayed after button label
   */
  trailingIcon?: React.ReactNode;
  /**
   * Is button disabled
   */
  disabled?: boolean;
  /**
   * Button can bring attention to itself
   */
  frameAnimation?: 'none' | 'pulsing';
}

export const Button: React.FC<React.PropsWithChildren<ButtonProps>> = props => {
  const {
    children,
    variant = 'solid',
    size = 'M',
    leadingIcon,
    trailingIcon,
    frameAnimation = 'none',
  } = props;

  const divider =
    size === 'S' ? null : variant === 'solid' ? (
      <IconDivider variant={variant} />
    ) : (
      <Reveal size={120} spread={0.5}>
        <IconDivider variant={variant} />
      </Reveal>
    );

  return (
    <Root
      variant={variant}
      size={size}
      frameAnimation={frameAnimation}
      {...props}
    >
      <Reveal inactiveOpacity={0.7} size={240} spread={0.5}>
        <ButtonRoot {...props} variant={variant}>
          <RevealBorder buttonSize={size} size={120} spread={0.5} />
          {leadingIcon && (
            <React.Fragment>
              <LeadingIcon size={size}>{leadingIcon}</LeadingIcon>
              {divider}
            </React.Fragment>
          )}
          <Label size={size} variant={variant}>
            {children}
          </Label>
          {trailingIcon && (
            <React.Fragment>
              {divider}
              <TrailingIcon size={size}>{trailingIcon}</TrailingIcon>
            </React.Fragment>
          )}
        </ButtonRoot>
      </Reveal>
    </Root>
  );
};

const RootComponent: React.FC<React.PropsWithChildren<Partial<ButtonProps>>> = ({
  variant,
  size,
  frameAnimation,
  trailingIcon,
  leadingIcon,
  disabled,
  ...props
}) => <div {...(props as any)} />;

const hiddenFrame = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 0;
  }
`;
const pulsingFrame = keyframes`
  0% {
    transform: scale(1);
    opacity: 0.2;
  }
  50% {
    transform: scale(2);
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
`;

const Root = styled(RootComponent)`
  position: relative;
  display: block;
  ${displayHelper};

  &::before,
  &::after {
    content: '';
    position: absolute;
    z-index: -1;
    box-sizing: border-box;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: ${({ size }) => (size === 'S' ? 32 : 4)}px;
    border: 1px solid ${({ theme }) => theme.foreground};
    animation: ${({ frameAnimation }) =>
        frameAnimation === 'pulsing' ? pulsingFrame : hiddenFrame}
      2s ease-out infinite;
    pointer-events: none;
  }
  &::before {
    animation-delay: 0.2s;
  }
  &::before {
    animation-delay: 0.4s;
  }
`;

const ButtonRootComponent: React.FC<React.PropsWithChildren<ButtonProps>> = ({
  variant,
  leadingIcon,
  trailingIcon,
  ...props
}) => <button {...props} />;

const ButtonRoot = styled(ButtonRootComponent)`
  position: relative;
  display: inline-flex;
  box-sizing: border-box;
  background: ${({ theme, variant }) =>
    variant === 'solid' ? theme.foreground : 'transparent'};
  border: none;
  color: ${({ theme, variant }) =>
    variant === 'solid' ? theme.background : theme.foreground};
  border-radius: ${({ size }) => (size === 'S' ? 32 : 4)}px;
  font-weight: ${({ variant }) => (variant === 'solid' ? 500 : 400)};
  padding: 0;
  margin: 0;
  cursor: pointer;
  height: ${({ size }) => (size === 'S' ? 32 : 48)}px;
  width: 100%;
  transition: all ${({ theme }) => theme.transition}, opacity 50ms;

  &:disabled {
    opacity: 0.25;
  }

  &:focus {
    outline: none;
  }

  &:active {
    opacity: 0.5;

    &:disabled {
      opacity: 0.25;
    }
  }
`;

const RevealBorderComponent: React.FC<React.PropsWithChildren<{
  buttonSize: ButtonProps['size'];
} & Pick<ButtonProps, 'variant'> &
  RevealProps>> = ({ buttonSize, variant, ...props }) => <Reveal {...props} />;

const RevealBorder = styled(RevealBorderComponent)`
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  box-sizing: border-box;
  border-radius: ${({ buttonSize }) => (buttonSize === 'S' ? 32 : 4)}px;
  border: 1px solid
    ${({ theme, variant }) =>
      variant === 'solid' ? 'transparent' : theme.foreground};
`;

const IconComponent: React.FC<React.PropsWithChildren<Pick<ButtonProps, 'size'>>> = ({
  size,
  ...props
}) => <div {...props} />;

const Icon = styled(IconComponent)`
  margin: ${({ size }) => (size === 'S' ? 4 : 12)}px 12px;
  line-height: 0;
`;

const LeadingIcon = styled(Icon)`
  margin-right: ${({ size }) => (size === 'S' ? -8 : 12)}px;
`;

const TrailingIcon = styled(Icon)`
  margin-left: ${({ size }) => (size === 'S' ? -8 : 12)}px;
`;

const IconDividerComponent: React.FC<React.PropsWithChildren<Pick<ButtonProps, 'variant'>>> = ({
  variant,
  ...props
}) => <div {...props} />;

const IconDivider = styled(IconDividerComponent)`
  background: ${({ theme, variant }) =>
    rgba(
      variant === 'solid' ? theme.background : theme.foreground,
      variant === 'solid' ? theme.divider.opacity : 1
    )};
  width: ${({ theme }) => theme.divider.width}px;
  height: 46px;
  margin-top: 1px;
`;

const LabelText = styled.span<ButtonProps>(({ size, theme }) => {
  const buttonLabelStyle = theme.typography.button.label[size as 'M'];

  return {
    ...theme.typography.button.label.default,
    ...buttonLabelStyle,
  };
});

const Label = styled(LabelText)`
  display: inline-block;
  color: currentColor;
  margin: ${({ size }) => (size === 'S' ? '10px 16px' : '16px 24px')};
  flex-grow: 1;
  text-decoration: none;
  font-weight: ${({ variant, size }) =>
    variant !== 'solid' && size === 'M' ? 500 : 500};
  white-space: nowrap;
`;
