import React from 'react';
import { rgba } from 'polished';
import styled from 'styled-components';
import { Typography } from '../Typography';

export interface ProgressProps {
  className?: string;
  max: number;
  maxLabel?: string;
  value: number;
  valueLabel?: string;
  pendingValue?: number;
  rangeStart?: number;
  rangeEnd?: number;
  rangeLabel?: string;
}

export const Progress: React.FC<React.PropsWithChildren<ProgressProps>> = props => {
  const { valueLabel, maxLabel, rangeEnd, rangeStart, rangeLabel } = props;
  return (
    <Root>
      {valueLabel && maxLabel && (
        <Labels>
          <LabelsValue>{valueLabel}</LabelsValue>
          <LabelsMax>{maxLabel}</LabelsMax>
        </Labels>
      )}

      <ProgressBar>
        {props.value ? <Value {...props} /> : null}
        {props.pendingValue ? <PendingValue {...props} /> : null}
      </ProgressBar>

      {rangeEnd && rangeStart && (
        <Range {...props}>
          <RangeThumbStart />
          <RangeThumbEnd />
          <RangeLine />
          <RangeLabel>{rangeLabel}</RangeLabel>
        </Range>
      )}
    </Root>
  );
};

const ProgressStyledComponent: React.FC<React.PropsWithChildren<ProgressProps>> = ({
  children,
  className,
}) => <div className={className}>{children}</div>;

const Root = styled.div`
  position: relative;
  color: ${({ theme }) => theme.foreground};
`;

const Labels = styled(Typography.Caption)`
  display: block;
  overflow: hidden;
  margin-bottom: 4px;
  line-height: 1.2em;
`;
const LabelsValue = styled.div`
  float: left;
`;
const LabelsMax = styled.div`
  float: right;
`;

const Value = styled(ProgressStyledComponent)`
  position: absolute;
  top: 0;
  left: 0;
  width: ${({ max, value }) => (100 / max) * value!}%;
  margin-bottom: -6px;
  height: 6px;
  border-radius: 50px 0 0 50px;
  background: ${({ theme }) => theme.foreground};
  transition: ${({ theme }) => theme.transition};
`;

const PendingValue = styled(ProgressStyledComponent)`
  position: absolute;
  top: 0;
  left: 0;
  width: ${({ max, pendingValue }) => (100 / max) * pendingValue!}%;
  margin-bottom: -6px;
  height: 6px;
  border-radius: 8px 0 0 8px;
  opacity: 0.25;
  background: ${({ theme }) => theme.foreground};
  transition: ${({ theme }) => theme.transition};
`;

const ProgressBar = styled.div`
  position: relative;
  display: block;
  margin-bottom: 4px;
  width: 100%;
  height: 6px;
  transition: ${({ theme }) => theme.transition};
  border-radius: 50px;
  background: ${({ theme }) => rgba(theme.foreground, 0.05)};
  box-shadow: inset 0 0 0 1px ${({ theme }) => rgba(theme.foreground, 0.2)};
  overflow: hidden;
  transition: ${({ theme }) => theme.transition};
`;

const Range = styled(ProgressStyledComponent)`
  position: relative;
  width: ${({ rangeEnd = 0, rangeStart = 0, max }) =>
    (100 / max) * (rangeEnd - rangeStart)}%;
  left: ${({ rangeStart = 0, max }) => (100 / max) * rangeStart}%;
`;

const RangeThumb = styled.div`
  position: absolute;
  width: 1px;
  height: 7px;
  background: ${({ theme }) => theme.foreground};
`;
const RangeThumbStart = styled(RangeThumb)`
  left: 0;
`;
const RangeThumbEnd = styled(RangeThumb)`
  right: 0;
`;

const RangeLine = styled.div`
  position: absolute;
  top: 3px;
  width: 100%;
  height: 1px;
  background: ${({ theme }) => theme.foreground};
`;

const RangeLabel = styled(Typography.Caption)`
  padding-top: 4px;
  text-align: center;
`;
