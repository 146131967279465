import { Theme, DefaultTheme } from './DefaultTheme';

export const DarkTheme: Theme = {
  ...DefaultTheme,
  dark: true,
  background: '#000000',
  foreground: '#FFFFFF',

  divider: {
    ...DefaultTheme.divider,
    opacity: 0.25,
  },

  reveal: {
    ...DefaultTheme.reveal,
    inactiveOpacity: 0.25,
    activeOpacity: 1,
  },
};
