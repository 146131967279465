import React from 'react';
import styled from 'styled-components';
import {
  responsiveHelper,
  ResponsiveProps,
} from './../../utils/responsiveHelper';
import { typographyHelper, TypographyProps } from './utils/typographyHelper';

export interface LinkProps extends TypographyProps, ResponsiveProps<'display'> {
  className?: string;
  href?: string;
  target?: string;
  alt?: string;
  download?: boolean;
  accent?: boolean;
}

export const Link = React.forwardRef<
  HTMLAnchorElement,
  React.PropsWithChildren<LinkProps>
>((props, ref) => {
  const { textDecoration = 'underline', ...rest } = props;
  return <Root ref={ref} textDecoration={textDecoration} {...rest} />;
});

const RootComponent = React.forwardRef<
  HTMLAnchorElement,
  React.PropsWithChildren<LinkProps> & React.RefAttributes<HTMLAnchorElement>
>(({ accent, textAlign, textDecoration, textTransform, ...props }, ref) => (
  <a ref={ref} {...props} />
));

const RootWithTheme = styled(RootComponent)(({ theme }) => ({
  ...theme.typography.link,
  color: theme.foreground,
}));

const Root = styled(RootWithTheme)`
  ${typographyHelper}
  ${responsiveHelper('display')}
  color: ${({ theme, accent }) => (accent ? theme.accent : theme.foreground)};
`;
