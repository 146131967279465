import { css } from 'styled-components';
import { Theme } from '../themes';
import { ResponsiveProperty, getResponsiveProperty } from './responsive';
import { AutoType, SpacingType } from './spacing';

export type MarginType = ResponsiveProperty<
  | SpacingType
  | AutoType
  | -4
  | -8
  | -12
  | -16
  | -24
  | -32
  | -40
  | -64
  | -80
  | -120
  | -160
  | -240
>;

export interface MarginProps {
  margin?: MarginType;
  marginTop?: MarginType;
  marginRight?: MarginType;
  marginBottom?: MarginType;
  marginLeft?: MarginType;
}

export const marginHelper = css<MarginProps>(({ theme, margin, ...props }) => {
  return css`
    ${getResponsiveProperty(
      'marginTop',
      typeof props.marginTop === 'undefined' ? margin : props.marginTop,
      theme as Theme
    )}
    ${getResponsiveProperty(
      'marginRight',
      typeof props.marginRight === 'undefined' ? margin : props.marginRight,
      theme as Theme
    )}
      ${getResponsiveProperty(
      'marginBottom',
      typeof props.marginBottom === 'undefined' ? margin : props.marginBottom,
      theme as Theme
    )}
      ${getResponsiveProperty(
      'marginLeft',
      typeof props.marginLeft === 'undefined' ? margin : props.marginLeft,
      theme as Theme
    )}
  `;
});
