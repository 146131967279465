import { CSSProperties } from 'react';
import { css } from 'styled-components';
import { Theme } from '../themes';
import { ResponsiveProperty, getResponsiveProperty } from './responsive';

export type ResponsiveType<T extends keyof CSSProperties> = ResponsiveProperty<
  CSSProperties[T]
>;

export type ResponsiveProps<T extends keyof CSSProperties> = {
  [key in T]?: ResponsiveType<T>;
};

export const responsiveHelper = (cssProperty: keyof CSSProperties) =>
  css<ResponsiveProps<typeof cssProperty>>(
    ({ theme, [cssProperty]: prop, ...props }) => {
      // const prop = props[cssProperty];
      return css`
        ${getResponsiveProperty(cssProperty as any, prop, theme as Theme)};
      `;
    }
  );
