import Link from 'next/link';
import { rgba } from 'polished';
import React from 'react';
import styled, { keyframes } from 'styled-components';
import { BartaxyzIcon } from 'xyz-icon-set-react';
import { useSystemTheme } from '../../../layouts/utils/useSystemTheme';
import { useCalendar } from '../../../lib/calendar';
import { useScrollInteraction } from '../../hooks/useScrollInteraction';
import { Breadcrumb, BreadcrumbItemType } from '../Breadcrumb';
import { ContextualPopup } from '../ContextualPopup/ContextualPopup';
import { ContextualPopupHeading } from '../ContextualPopup/ContextualPopupHeading';
import { ContextualPopupSection } from '../ContextualPopup/ContextualPopupSection';
import { ContextualPopupWorldMap } from '../ContextualPopup/ContextualPopupWorldMap';
import { Divider } from '../Divider';
import { Typography } from '../Typography';

export interface PageFrameProps {
  breadcrumb?: BreadcrumbItemType[];
}

export const PageFrame: React.FC<React.PropsWithChildren<PageFrameProps>> = ({
  children,
  breadcrumb,
}) => {
  const { currentCalendarEvent, currentCalendarLocation } = useCalendar();

  return (
    <Root>
      <Aside>
        <Link href="/" legacyBehavior>
          <Typography.Link>
            <Logo />
          </Typography.Link>
        </Link>

        <ContextualPopup behaviour="click" handleComponent={PulsingCircle}>
          {currentCalendarLocation && (
            <>
              <ContextualPopupHeading>
                {currentCalendarLocation.title}
              </ContextualPopupHeading>

              <ContextualPopupWorldMap
                long={currentCalendarLocation.long}
                lat={currentCalendarLocation.lat}
              />
            </>
          )}

          <Divider.Horizontal />

          <ContextualPopupSection>
            <Typography.Caption textAlign="center">
              This widget is connected directly to my Google calendar.{' '}
              {/* To learn
              more,{' '}
              <Typography.Link
                accent={true}
                href="https://calendar.google.com/calendar/embed?src=o7fuj1odginv2mhkmpibbinhv8%40group.calendar.google.com&ctz=America%2FToronto"
              >
                read my blog post{' '}
              </Typography.Link>{' '}
              about how it was implemented. */}
            </Typography.Caption>
          </ContextualPopupSection>
        </ContextualPopup>

        <CurrentlyWorkingOn size="L" fontWeight={600}>
          Currently{' '}
          {currentCalendarEvent ? (
            currentCalendarEvent.description ? (
              <span
                dangerouslySetInnerHTML={{
                  __html: currentCalendarEvent.description,
                }}
              />
            ) : (
              <Typography.Link
                accent={true}
                href="https://calendar.google.com/calendar/embed?src=o7fuj1odginv2mhkmpibbinhv8%40group.calendar.google.com&ctz=America%2FToronto"
              >
                {currentCalendarEvent?.summary}
              </Typography.Link>
            )
          ) : currentCalendarEvent === null ? (
            <Typography.Link
              accent={true}
              href="https://calendar.google.com/calendar/embed?src=o7fuj1odginv2mhkmpibbinhv8%40group.calendar.google.com&ctz=America%2FToronto"
            >
              enjoying life
            </Typography.Link>
          ) : (
            <>...</>
          )}
          <CurrentlyWorkingOnQuestionMark></CurrentlyWorkingOnQuestionMark>
        </CurrentlyWorkingOn>
      </Aside>

      <Menu>
        {[
          { href: '/blog', label: 'Blog' },
          {
            href: 'https://bartaxyz.substack.com/',
            label: 'Mailing List',
          },
          {
            href: '/pet-projects',
            label: 'Pet Projects',
          },
          {
            href: '/books',
            label: 'Reading Challenge 2023',
          },
          {
            href: '/wiki',
            label: 'Wiki',
          },
          /* {
            href: '/cv',
            label: 'CV',
          }, */
        ].map(({ href, label }) => (
          <Link href={href} key={href} legacyBehavior>
            <MenuItem
              selected={
                typeof location !== 'undefined' &&
                (location.pathname === href ||
                  location.pathname.startsWith(href))
              }
            >
              <Typography.Caption size="L" fontWeight={600}>
                {label}
              </Typography.Caption>
            </MenuItem>
          </Link>
        ))}
      </Menu>

      <PageContent>{children}</PageContent>

      <Aside variant="right">
        <Breadcrumb items={breadcrumb!} />
      </Aside>
    </Root>
  );
};

const Root = styled.div``;

const Aside = styled.div<{ variant?: 'left' | 'right' }>`
  width: 64px;
  height: 100%;
  position: fixed;
  z-index: 128;
  top: 0;
  border-right: 1px solid ${({ theme }) => rgba(theme.foreground, 0.1)};
  border-left: 1px solid ${({ theme }) => rgba(theme.foreground, 0.1)};
  border-right-width: ${({ variant = 'left' }) =>
    variant === 'right' ? '0' : '1px'};
  border-left-width: ${({ variant = 'left' }) =>
    variant === 'left' ? '0' : '1px'};
  left: ${({ variant = 'left' }) => (variant === 'left' ? '0' : 'auto')};
  right: ${({ variant = 'left' }) => (variant === 'left' ? 'auto' : '0')};

  display: flex;
  flex-direction: column;
  align-items: center;

  @media ${({ theme }) => theme.media.phone} {
    border: none;
    height: 64px;

    display: ${({ variant = 'left' }) =>
      variant === 'right' ? 'none' : 'flex'};
  }
`;

const CurrentlyWorkingOn = styled(Typography.Caption)`
  writing-mode: vertical-lr;

  @media ${({ theme }) => theme.media.phone} {
    display: none;
  }
`;

const CurrentlyWorkingOnQuestionMark = styled.sup`
  margin-inline-start: 12px;
`;

const Menu = styled.div`
  box-sizing: border-box;
  width: calc(100% - (2 * 64px));
  left: 64px;
  height: 64px;
  position: fixed;
  z-index: 64;
  top: 0;
  right: 0;
  display: flex;
  align-items: center;
  padding: 0 16px;
  gap: 8px;
  overflow-x: auto;

  &::-webkit-scrollbar {
    display: none;
  }

  @media ${({ theme }) => theme.media.phone} {
    width: 100%;
    left: 0;
    padding-left: 64px;
    border-bottom: 1px solid ${({ theme }) => rgba(theme.foreground, 0.1)};
  }
`;

React.forwardRef;

const MenuItemComponent = React.forwardRef<
  HTMLDivElement,
  {
    selected: boolean;
    children: React.ReactNode;
  }
>(({ selected, ...props }, ref) => {
  const { background, foreground } = useSystemTheme();
  const { elementRef } = useScrollInteraction(
    {
      domain: [80, 320],
      range: [0, 1],
      clamp: true,
    },
    (element, value) => {
      (element.style as any).backdropFilter = `blur(${value * 10}px)`;
      (element.style as any).WebkitBackdropFilter = `blur(${value * 10}px)`;

      element.style.opacity = `${value}`;
    }
  );

  return (
    <div {...props} ref={ref}>
      <MenuItemBackground ref={elementRef} />
      {props.children}
    </div>
  );
});

const MenuItemBackground = styled.div`
  position: absolute;
  box-sizing: border-box;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: 8px;
  background: ${({ theme }) => rgba(theme.background, 0.5)};
  border: 1px solid ${({ theme }) => rgba(theme.foreground, 0.1)};
  z-index: -1;
`;

const MenuItem = styled(MenuItemComponent)`
  padding: 8px 16px;
  cursor: pointer;
  position: relative;
  /* backdrop-filter: blur(8px);
  background: ${({ theme }) => rgba(theme.background, 0.5)}; */
  color: ${({ theme, selected }) =>
    selected ? theme.accent : theme.foreground};
  border-radius: 8px;
  overflow: hidden;
  white-space: nowrap;
  flex-shrink: 0;
`;

const LogoAnimation = keyframes`
  80% {
    transform: rotateY(0deg);
  }
  90% {
    transform: rotateY(180deg);
  }
  100% {
    transform: rotateY(0deg);
  }
`;

const Logo = styled(BartaxyzIcon)`
  animation: ${LogoAnimation} 10s linear infinite;
  margin: 20px;
`;

const PulsingCircleAnimation = keyframes`
  0% {
    width: 8px;
    height: 8px;
    opacity: 1;
  }
  40% {
    opacity: 1;
  }
  100% {
    width: 24px;
    height: 24px;
    opacity: 0;
  }
`;

const PulsingCircle = styled.div`
  position: relative;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: #d63b3b;
  margin: 28px;
  margin-block: 12px;

  @media ${({ theme }) => theme.media.phone} {
    display: none;
  }

  &::before,
  &::after {
    content: '';
    position: absolute;
    box-sizing: border-box;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 8px;
    height: 8px;
    border-radius: 50%;
    border: 0.5px solid #d63b3b;
    animation: ${PulsingCircleAnimation} 2s cubic-bezier(0, 0, 0.2, 1) infinite;
  }

  &::after {
    animation-delay: 1s;
  }
`;

const PageContent = styled.div`
  width: calc(100% - (2 * 64px));
  margin-left: 64px;
  margin-right: 64px;
  margin-top: 64px;

  @media ${({ theme }) => theme.media.phone} {
    margin-left: 0;
    margin-right: 0;
    width: 100%;
  }
`;
