import React from 'react';
import { Page as PageComponent } from './Page';

import { PageBreadcrumbHeader } from './PageBreadcrumbHeader';
import { PageFrame } from './PageFrame';

export class Page extends React.Component {
  public static BreadcrumbHeader = PageBreadcrumbHeader;
  public static Frame = PageFrame;

  render() {
    return <PageComponent {...this.props} />;
  }
}
