import { scaleLinear } from 'd3';
import { useEffect, useRef } from 'react';
import { usePrefersColorScheme } from '../../layouts/utils/usePrefersColorScheme';

export const useScrollInteraction = <T extends HTMLElement = HTMLDivElement>(
  preset: { domain: number[]; range: number[]; clamp?: boolean },
  callback: (element: T, value: number) => void
) => {
  const elementRef = useRef<T>(null);
  const { colorScheme } = usePrefersColorScheme();

  const presetController = scaleLinear()
    .domain(preset.domain)
    .range(preset.range)
    .clamp(!!preset.clamp);

  const interactionHandler = () => {
    if (!elementRef.current) return;

    const { scrollTop } = document.scrollingElement as typeof document.body;

    callback(elementRef.current, presetController(scrollTop));
  };

  useEffect(() => {
    /**
     * When in doubt, setTimeout comes to rescue. This assures that the code inside
     * interactionHandler can already use the DOM with the current color scheme.
     */
    setTimeout(() => {
      interactionHandler();
    }, 1);
  }, [colorScheme]);

  useEffect(() => {
    interactionHandler();

    addEventListener('scroll', interactionHandler);
    addEventListener('resize', interactionHandler);

    return () => {
      removeEventListener('scroll', interactionHandler);
      removeEventListener('resize', interactionHandler);
    };
  }, []);

  interactionHandler();

  return { elementRef };
};
