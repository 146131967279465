import React from 'react';
import styled from 'styled-components';
import {
  SpacingTypographyProps,
  spacingTypographyHelper,
} from './utils/spacingTypographyHelper';
import { TypographyProps, typographyHelper } from './utils/typographyHelper';

export interface ParagraphProps
  extends TypographyProps,
    SpacingTypographyProps {
  className?: string;
  variant?: 'text';
  as?: 'p' | 'span' | 'div';
  size?: 'M' | 'L';
  children?: React.ReactNode;
}

export const Paragraph: React.FC<ParagraphProps> = (props) => {
  const { children } = props;
  return <Root {...props}>{children}</Root>;
};

const RootComponent: React.FC<ParagraphProps> = (props) => {
  const { className, children, as } = props;

  return React.createElement(as || 'p', { className }, children);
};

const RootWithTheme = styled(RootComponent)(({ theme, variant, size }) => {
  const paragraphStyles =
    variant === 'text'
      ? theme.typography.textParagraph
      : theme.typography.paragraph;

  const paragraphStyle = paragraphStyles[size as 'M'];

  return {
    ...paragraphStyles.default,
    ...paragraphStyle,
    color: theme.foreground,
  };
});

const Root = styled(RootWithTheme)`
  ${typographyHelper}
  ${spacingTypographyHelper}
`;
