import { useEffect, useState } from 'react';

import { Theme, DefaultTheme } from '../../ui/themes/DefaultTheme';
import { DarkTheme } from '../../ui/themes/DarkTheme';

export interface MediaQueryEvent {
  matches: boolean;
  media: string;
}

export const useSystemTheme = (defaultTheme: Theme = DefaultTheme) => {
  const [themeState, setTheme] = useState<Theme>(DefaultTheme);

  useEffect(() => {
    const { matchMedia } = window;

    if (!matchMedia) {
      return;
    }

    const media = matchMedia('(prefers-color-scheme: dark)');

    const mediaHandler = (event: { matches: boolean }) => {
      if (event.matches) {
        setTheme(DarkTheme);
      } else {
        setTheme(DefaultTheme);
      }
    };

    mediaHandler(media);

    media.addEventListener('change', mediaHandler);

    return () => {
      media.removeEventListener('change', mediaHandler);
    };
  }, [defaultTheme]);

  return themeState;
};
