import { useEffect, useState } from 'react';

export const usePrefersColorScheme = () => {
  const [colorScheme, setColorScheme] = useState<'light' | 'dark'>('light');

  useEffect(() => {
    const media = matchMedia('(prefers-color-scheme: dark)');

    const mediaHandler = (event: { matches: boolean }) => {
      if (event.matches) {
        setColorScheme('dark');
      } else {
        setColorScheme('light');
      }
    };

    mediaHandler(media);

    media.addEventListener('change', mediaHandler);

    return () => {
      media.removeEventListener('change', mediaHandler);
    };
  }, []);

  return { colorScheme };
};
