import { css } from 'styled-components';
import { AutoType, MaxWidthType } from './spacing';

export interface MaxWidthProps {
  maxWidth?: MaxWidthType | AutoType;
}

export const maxWidthHelper = css<MaxWidthProps>(({ maxWidth }) => ({
  maxWidth,
}));
