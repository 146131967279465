import React from 'react';

import { TableList } from './TableList';

export class Table extends React.Component {
  public static List = TableList;

  render() {
    return null;
  }
}
