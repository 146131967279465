import React from 'react';
import { ExternalThinIcon } from 'xyz-icon-set-react';
import { Divider } from '../Divider';
import { Typography } from '../Typography';
import { ContextualPopup, ContextualPopupProps } from './ContextualPopup';
import { ContextualPopupHeading } from './ContextualPopupHeading';
import { ContextualPopupSection } from './ContextualPopupSection';

export interface ContextualPopupHelpProps extends ContextualPopupProps {
  heading: string;
  description: string;
  link?: string;
}

export const ContextualPopupHelp: React.FC<React.PropsWithChildren<
  ContextualPopupHelpProps
>> = ({ handle, heading, description, link, ...props }) => (
  <Typography.Mark variant="transparent">
    <ContextualPopup
      behaviour="click"
      handle={
        <>
          {handle}
          <Typography.Base opacity={0.5}>
            <sup>?</sup>
          </Typography.Base>
        </>
      }
      {...props}
    >
      <ContextualPopupHeading>{heading}</ContextualPopupHeading>
      <ContextualPopupSection>
        <Typography.Caption textAlign="center">
          {description}
        </Typography.Caption>
      </ContextualPopupSection>

      {link && (
        <>
          <Divider.Horizontal />
          <ContextualPopupSection>
            <Typography.Caption textAlign="center" lineBreak="anywhere">
              <Typography.Link href={link}>
                {link}

                <ExternalThinIcon width={12} height={12} />
              </Typography.Link>
            </Typography.Caption>
          </ContextualPopupSection>
        </>
      )}
    </ContextualPopup>
  </Typography.Mark>
);
