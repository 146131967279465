import { css } from 'styled-components';

export interface SpacingTypographyProps {
  marginTop?: 0;
  marginBottom?: 0;
}

export const spacingTypographyHelper = css<SpacingTypographyProps>(
  ({ marginTop, marginBottom }: SpacingTypographyProps) => {
    return css({
      marginTop,
      marginBottom,
      '&:first-child': {
        marginTop: 0,
      },
      '&:last-child': {
        marginBottom: 0,
      },
    });
  }
);
